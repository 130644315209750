





























































































































































































































































































































































































































































































































































































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import ValidatorMixin from '@/modules/shared/mixins/ValidatorMixin'

import BacteriaFilterItem from '@/modules/shared/entities/BacteriaFilterItem'
import ExperimentModel from '@/modules/shared/entities/form-models/Experiment'
import Country from '@/modules/shared/entities/form-models/Country'

import ConfirmDialog from '@/modules/shared/components/ConfirmDialog.vue'
import DatePicker from '@/modules/shared/components/DatePicker.vue'
import DeleteIcon from '@/modules/shared/components/icons/DeleteIcon.vue'
import DialogSection from '@/modules/shared/components/DialogSection.vue'
import PrimaryButton from '@/modules/shared/components/PrimaryButton.vue'
import SearchIcon from '@/modules/shared/components/icons/SearchIcon.vue'
// TODO: export this component to shared module
import ResistomeAutocomplete from '@/modules/bacteria/components/filter/ResistomeAutocomplete.vue'

const AdminExperimentModule = namespace('admin_experiment')
const AuthModule = namespace('auth')

@Component({
  components: {
    ConfirmDialog,
    DatePicker,
    DeleteIcon,
    DialogSection,
    PrimaryButton,
    ResistomeAutocomplete,
    SearchIcon,
  },
})
export default class ExperimentForm extends Mixins(ValidatorMixin) {
  @AuthModule.Getter
  private readonly isAdmin!: boolean

  @AdminExperimentModule.Getter
  private readonly formModel!: ExperimentModel

  @AdminExperimentModule.Getter
  private readonly species!: BacteriaFilterItem[]

  @AdminExperimentModule.Getter
  private readonly plasmidome!: BacteriaFilterItem[]

  @AdminExperimentModule.Getter
  private readonly antigenH!: BacteriaFilterItem[]

  @AdminExperimentModule.Getter
  private readonly antigenO!: BacteriaFilterItem[]

  @AdminExperimentModule.Getter
  private readonly virulome!: BacteriaFilterItem[]

  @AdminExperimentModule.Getter
  private readonly heavyMetal!: BacteriaFilterItem[]

  @AdminExperimentModule.Getter
  private readonly serovar!: BacteriaFilterItem[]

  @AdminExperimentModule.Getter
  private readonly clermontTyping!: BacteriaFilterItem[]

  @AdminExperimentModule.Getter
  private readonly sequencer!: BacteriaFilterItem[]

  @AdminExperimentModule.Getter
  private readonly source!: BacteriaFilterItem[]

  @AdminExperimentModule.Getter
  private readonly origin!: BacteriaFilterItem[]

  @AdminExperimentModule.Getter
  private readonly kLocus!: string[]

  @AdminExperimentModule.Getter
  private readonly wzi!: string[]

  @AdminExperimentModule.Getter
  private readonly wzc!: string[]

  @AdminExperimentModule.Getter
  private readonly fimType!: string[]

  @AdminExperimentModule.Getter
  private readonly st!: string[]

  @AdminExperimentModule.Getter
  private readonly countries!: string[]

  @AdminExperimentModule.Getter
  private readonly cities!: string[]

  @AdminExperimentModule.Getter
  private readonly regions!: string[]

  @AdminExperimentModule.Getter
  private readonly effluxPump!: BacteriaFilterItem[]

  @AdminExperimentModule.Getter
  private readonly sccMecElement!: BacteriaFilterItem[]

  @AdminExperimentModule.Getter
  private readonly sAureusSpaType!: string[]

  @AdminExperimentModule.Mutation
  private readonly setFormModel!: (formModel: ExperimentModel|null) => Promise<void>

  @AdminExperimentModule.Action
  private readonly removeExperiment!: (id: number) => Promise<void>

  @AdminExperimentModule.Action
  private readonly saveExperiment!: (formModel: ExperimentModel|null) => Promise<void>

  @AdminExperimentModule.Action
  private readonly fetchSubSpecies!: (groupId: number) => Promise<BacteriaFilterItem[]>

  @Watch('formModel')
  updateModel(newValue: ExperimentModel|null = null) {
    if (newValue === null) {
      this.model = newValue
    } else {
      this.model = newValue?.clone()
      this.country = this.formModel.city.country
    }
  }

  private model: ExperimentModel|null = null

  private country: Country|undefined = undefined

  private confirmDialog = false

  private subSpecies: BacteriaFilterItem[] = []

  private get isOpen(): boolean {
    return this.model !== null
  }

  private set isOpen(open: boolean) {
    if (open === false) {
      this.setFormModel(null)
    }
  }

  private get isEditing(): boolean {
    return !!this.model?.id
  }

  private get showSubSpecies(): boolean {
    return Boolean(this.model?.specie?.id && this.subSpecies.length)
  }

  private resistomeFields = [
    { name: 'blactam', type: 'B_LACTAM' },
    { name: 'aminoglycoside', type: 'AMINOGLYCOSIDE' },
    { name: 'tetracycline', type: 'TETRACYCLINE' },
    { name: 'colistin', type: 'COLISTIN' },
    { name: 'quinolone', type: 'QUINOLONE' },
    { name: 'trimethoprim', type: 'TRIMETHOPRIM' },
    { name: 'sulphonamide', type: 'SULPHONAMIDE' },
    { name: 'fusidic_acid', type: 'FUSIDIC_ACID' },
    { name: 'glycopeptide', type: 'GLYCOPEPTIDE' },
    { name: 'oxazolidinone', type: 'OXAZOLIDINONE' },
    { name: 'rifampicin', type: 'RIFAMPICIN' },
    { name: 'phenicol', type: 'PHENICOL' },
    { name: 'fosfomycin', type: 'FOSFOMYCIN' },
    { name: 'nitroimidazole', type: 'NITROIMIDAZOLE' },
    { name: 'macrolide', type: 'MACROLIDE' },
  ]

  private antibiogramGroup = [
    ['mer', 'etp', 'ipm', 'cro'],
    ['caz', 'cfx', 'cpm', 'ctx'],
    ['nal', 'cip', 'amc', 'atm'],
    ['fos', 'ami', 'gen', 'sxt'],
    ['eno', 'chl', 'cep', 'ctf'],
    ['amp', 'tet', 'tob', 'pit'],
    ['tig', 'lzd', 'azi', 'lxv'],
    ['cli', 'pen', 'mup', 'van'],
    ['col'],
  ]

  private deleteExperiment(id: number) {
    this.removeExperiment(id)
    this.confirmDialog = false
  }

  private async submit() {
    if (!this.validate()) return

    try {
      await this.saveExperiment(this.model)

      this.reset()
    } catch (err) {
      console.error(err)
    }
  }

  @Watch('model.specie')
  private async onChangeSpecie(specie?: BacteriaFilterItem) {
    this.subSpecies = []

    if (specie) {
      try {
        this.subSpecies = await this.fetchSubSpecies(specie.id)
      } catch (_) { /** */ }
    }

    if (this.model && !this.subSpecies.some((item) => item.id === this.model?.sub_specie?.id)) {
      // eslint-disable-next-line
      this.model!.sub_specie = {}
    }
  }
}
