











import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Headline extends Vue {
  @Prop({ required: true })
  private readonly title!: string;

  @Prop()
  private readonly subtitle!: string;

  @Prop({ default: false, type: Boolean })
  private readonly dense!: boolean;
}
