






















import { Component, Prop, Vue } from 'vue-property-decorator'

import Icon from '@/modules/shared/components/Icon.vue'

@Component({
  components: {
    Icon,
  },
})
export default class Breadcrumbs extends Vue {
  @Prop()
  private readonly items!: unknown[]
}
