























































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { debounce } from '@/modules/shared/utils/functions'

import BacteriaFilter from '@/modules/shared/entities/BacteriaFilter'
import ExperimentModel from '@/modules/shared/entities/form-models/Experiment'

import DeleteIcon from '@/modules/shared/components/icons/DeleteIcon.vue'
import SearchIcon from '@/modules/shared/components/icons/SearchIcon.vue'
import PrimaryButton from '@/modules/shared/components/PrimaryButton.vue'
import Icon from '@/modules/shared/components/Icon.vue'

const AdminExperimentModule = namespace('admin_experiment')

@Component({
  components: {
    DeleteIcon,
    SearchIcon,
    PrimaryButton,
    Icon,
  },
})
export default class ExperimentFilter extends Vue {
  @AdminExperimentModule.State
  private readonly species!: unknown[]

  @AdminExperimentModule.Getter
  private readonly filter!: BacteriaFilter

  @AdminExperimentModule.Action
  private readonly fetchExperiments!: (filter: BacteriaFilter) => Promise<void>

  @AdminExperimentModule.Mutation
  private readonly setFormModel!: (formModel: ExperimentModel) => Promise<void>

  private debouncedApplyFilter = debounce(this.applyFilter, 750)

  private applyFilter(name: string, value: string|number|number[]): void {
    const newFilter = this.filter.copyWith({
      [name]: value,
      page: 1,
    })

    this.fetchExperiments(newFilter)
  }

  private clearSearch() {
    const newFilter = BacteriaFilter.empty()
    this.fetchExperiments(newFilter)
  }

  private addExperiment() {
    this.setFormModel(ExperimentModel.empty())
  }
}
